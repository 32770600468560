@import "~@angular/material/theming";

// Include non-theme styles for core.
@include mat-core();

// Global styles
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
